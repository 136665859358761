*,
*::before,
*:after {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  margin: 0;
}
html{
  scroll-behavior: smooth;
}
body {
  font-family: var(--body-font);
  background: var(--body-bg);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  max-width: $width100;
  overflow-x: hidden;
  font-size: 16px;
  color: var(--font-color);
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

img,
picture,
picture img {
  display: block;
  max-width: 100%;
  height: auto;
}

section,
figure,
article {
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: 1em;

  &:last-child,
  &:only-child,
  &:last-of-type,
  &:only-of-type {
    margin-bottom: 0;
  }
}
.logo{
  cursor: pointer;
}