$size-mobile: 3.5rem;

.main-nav {
  @include to(l) {
    position: fixed;
    display: flex;
    flex-direction: column;
    background-color: #03A9F5;
    align-items: center;
    justify-content: center;
    width: $width100;
    height: 100vh;
    // max-height: calc(100vh - var(--header-height));
    top: 0;
    left: 0;
    transition: transform .5s;
    opacity: 1;
    transform-origin: center;
    transform: translateX(-100%);
    z-index: -1;
    &.active {
      visibility: visible;
      opacity: 1;
      transform: translateX(0);
    }
  }
  @include from(l) {
    display: flex;
    gap: 1em;
    align-items: center;
    justify-content: flex-end;
  }
  color: var(--body-bg)
}

.main-menu,
.social {
  @extend %list-style;
}

.main-menu {
  position: relative;

  @include to(l) {
    margin-bottom: $size-mobile;
    font-size: $size-mobile * .5;

    li {
      position: relative;
      line-height: $size-mobile;
      padding-left: $size-mobile * .75;
      
      &:last-child a::before {
        display: none;
      }
    }

    a {
      display: block;
      position: relative;

      &::before,
      &::after {
        content: '';
        position: absolute;
        display: block;
        left: -$size-mobile * .75;
        top: 50%;
      }

      &::after {
        width: $size-mobile * .25;
        height: $size-mobile * .25;
        background: #03A9F5;
        transform: translateY(-50%);
        border-radius: 50%;
        border: 2px solid var(--body-bg);
      }

      &::before {
        width: 1px;
        height: $size-mobile;
        background-color: var(--body-bg);
        transform: translateX(6px);
      }

      &.active:after {
        background: var(--body-bg);
      }

    }
  }

  @include from(l) {
    display: flex;
    justify-content: flex-end;
    li{
      margin-right: 2em;

    }
  }
}

.social {
  width: 70%;
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(5, 1fr);

  @include from(l) {

    width: auto;
    margin: 0 !important;
  }

  a {
    width: $size-mobile * .5;
    height: $size-mobile * .5;
    display: flex;
    justify-content: center;
    align-items: center;

    @include from(l) {
      width: $size-mobile * .4;
    }
  }

  li {
    display: flex;
    justify-content: center;
    align-items: center;

    &:nth-child(3) {
      svg {
        display: inline-block;
        transform: scale(.75);

      }
    }
  }

  svg {
    height: $size-mobile * .5;
    width: auto;
    fill: var(--body-bg);

    @include from(l) {
      width: $size-mobile * .4;
    }
  }
}


@media screen and (min-width: 1024px) {
  .main-menu a {
    transition: color .3s;

    &:hover,
    &.active {
      color: hsl(316, 87, 75);
      // text-shadow: 1px 1px lighten($color: #f021b6, $amount:30);
    }
  }

 
}
// .main-nav{
//   background-color:lime;
// }
// .main-menu{
//   background-color: tomato !important;
// }
.social.contac {
  @include from(l) {
    margin: 0 auto !important;
  }
}
.menu-other{
  @extend %list-style;
  transform-origin: center;
  transform: rotate(-30deg);
  margin-bottom: $size-mobile;
  font-size: $size-mobile * .5;
  @include fromTo(l,1400px) {
    transform: rotate(-30deg) translateX(-25%) translateY(10%) scale(.9);
  }

  li {
    position: relative;
    line-height: $size-mobile;
    padding-left: $size-mobile * .75;

    &:last-child a::before {
      display: none;
    }
  }

  a {
    display: block;
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      display: block;
      left: -$size-mobile * .75;
      top: 50%;
    }

    &::after {
      width: $size-mobile * .25;
      height: $size-mobile * .25;
      background: #03A9F5;
      transform: translateY(-50%);
      border-radius: 50%;
      border: 2px solid var(--body-bg);
      
    }

    &::before {
      width: 1px;
      height: $size-mobile;
      background-color: var(--body-bg);
      transform: translateX(6px);
    }

    &.active:after {
      background: var(--body-bg);
    }

  }
}